// // Body
// $body-bg: #f8fafc;

// // Typography
// $font-family-sans-serif: 'Nunito', sans-serif;
// $font-size-base: 0.9rem;
// $line-height-base: 1.6;

// // Colors
// $blue: #3490dc;
// $indigo: #6574cd;
// $purple: #9561e2;
// $pink: #f66d9b;
// $red: #e3342f;
// $orange: #f6993f;
// $yellow: #ffed4a;
// $green: #38c172;
// $teal: #4dc0b5;
// $cyan: #6cb2eb;

// Colors
$primary:         #859FDA;
$secondary:       #2C4A88;
$gray:            #7C7C7C;
$register-bg:     #DDE6FA;
$warning:         #F65F6E;
$green:           #71DD92;
$gray-ef:         #EFEFEF;
$gray-e6:         #E6E6E6;
$white-f0:        #f0f0f0;
$white-f9:        #F9F9F9;
$gray-b5:         #B5B5B5;
$gray-e:          #eeeeee;
$prime-light-bg:  #F0F2F8;
$gray-d5:         #D5D5D5;
$linearGradient:  linear-gradient(-10deg,#9FBAF6,#6F98F1);

// Backgrounds
$body-bg:     #F8F9FD;
$body-text:   $gray;

// Typography
$base-font:    'foco';
$base-size:    16px;
$line-height:  1.5;


// Font path
$font-path: '/fonts';
$font-slug: 'oog5v1';


// Bootstrap Overrides
$theme-colors: (
    "primary":    $primary,
    "secondary":  $secondary,
    "warning":    $warning
);


$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1600px
) !default;

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1560px
) !default;

$link-color:          #2C4A88;
$input-border-width:  0px;
$btn-border-width:    0px;
$input-placeholder-color: rgba($gray, 0.44);
$border-radius:       5px;
