// Fonts // Variables
@import 'variables';
@import "mixins";

// Fonts
@font-face {
    font-family: 'foco';
    src: url('#{$font-path}/foco_bold-webfont.woff2') format('woff2'),
    url('#{$font-path}/foco_bold-webfont.woff') format('woff'),
    url('#{$font-path}/foco_bold-webfont.ttf') format('truetype'),
    url('#{$font-path}/foco_bold-webfont.svg#focobold') format('svg');

    src: url('#{$font-path}/foco_bold-webfont.woff2') format('woff2'),
    url('#{$font-path}/foco_bold-webfont.woff') format('woff'),
    url('#{$font-path}/foco_bold-webfont.ttf') format('truetype'),
    url('#{$font-path}/foco_bold-webfont.svg#focobold') format('svg');

    font-weight: bold;
    font-style: normal;

}

  @font-face {
    font-family: 'foco';
    src: url('#{$font-path}/foco-webfont.woff2') format('woff2'),
    url('#{$font-path}/foco-webfont.woff') format('woff'),
    url('#{$font-path}/foco-webfont.ttf') format('truetype'),
    url('#{$font-path}/foco-webfont.svg#focoregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Vendor Custom
@import "app/custom/bootstrap-custom";
@import "app/custom/vue-multiselect-custom";
@import "app/custom/select2-custom";
@import "app/custom/floating-label";

// Blocks
@import "app/blocks/common";
@import "app/blocks/layout";
@import "app/blocks/auth-layout";
@import "app/blocks/auth-header";
@import "app/blocks/auth-footer";
@import "app/blocks/icon-fonts";
@import "app/blocks/dash-header";
@import "app/blocks/modal-custom";
@import "app/blocks/lost-pets-sidebar";

// Solar Blocks
@import "app/Solar/solar";

//Pages
@import "app/pages/login";
@import "app/pages/register";
@import "app/pages/dashboard";
@import "app/pages/pets-page";
@import "app/pages/notices";
@import "app/pages/citylaws";
@import "app/pages/lost-pets";
@import "app/pages/support";
