/* Common styles */

html {
  -webkit-text-size-adjust: none; /* Prevent font scaling in landscape */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  min-height: 100%;
}

body {
  font: #{$base-size}/#{$line-height} $base-font;
  color: $body-text;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6,
ul, ol, li,
form, input, textarea, select {
  margin: 0;
  padding: 0;
}

ul,li{list-style: none;}

img {
  border: 0;
}

.indenet{
  text-indent: 30px;
}

.f-14{
  font-size: 14px;
}

.jumbo-heading {
  font-size: 40px;
  font-weight: 500;
}

.page-heading {
  font-size: 32px;
  font-weight: 500;
}

.cursor-pointer {
  cursor: pointer;
}

.common-wrap {
  padding: 0 15px 15px 15px;
}

.box{
  border-radius: 10px;
  box-shadow: 0 0 3px rgba($black, 0.16);
  background-color: $white;
  overflow: hidden;
}

.warn-alert {
  background-color: $warning;
  border-radius: 10px;
  padding: 20px;
  color: $white;
  box-shadow: 0 0 3px rgba($black, 0.16);
  line-height: 1;
}

.city-alert{
  box-shadow: 0 0 3px rgba($black, 0.16);
  background-color: $white;
  border-radius: 10px;
  line-height: 1;
  overflow: hidden;
}

//table header style
.t-head{
  font-size: 15px;
  padding: 6px 5px;

  &.gray-ef{
    background-color: $gray-ef;
  }

  &.primary-ef {
    background-color: $primary;
    color: $white;
  }

}

.tr-bg {
  background: linear-gradient(173deg,#9FBAF6,#6F98F1);
  th,td {
    color: $white !important;
  }
}

//modal header style
.m-head{
  font-size: 18px;
  padding: 6px 5px;

  &.gray-ef{
    background-color: $gray-ef;
  }

  &.primary-ef {
    background-color: $primary;
    color: $white;
  }

}

.custom-btn-box {
  width: 100%;
  .btn{
    border-radius: 0;
    width: 100%;
    padding: 15px;
    font-size: 12px;
  }
}

.btn{
  &.btn-view-detail{
    color: $gray;
    background-color: $gray-ef;
    &.renew{
      color: $warning;
    }
  }

  &.btn-lost{
    color: $gray;
    background-color: $gray-e6;
    &.active{
      color: $white;
      background-color: $warning;
    }
  }
}

.text-bold{
  font-weight: bold;
}

.btn-ico {
  i,span {
    display: inline-block;
    vertical-align: middle;
  }
}

[x-cloak] {
    display: none !important;
}


@include media-breakpoint-up(md) {


  .common-wrap {
    padding: 0 15px 25px 15px;
  }

  .jumbo-heading {
    font-size: 54px;
  }

  .page-heading {
    font-size: 42px;
  }

  .custom-btn-box {
    .btn{
      font-size: 14px;
    }
  }

}


@include media-breakpoint-up(xl) {

  .common-wrap {
    padding: 0 15px 20px 15px;
  }

  .custom-btn-box {
    .btn{
      font-size: 16px;
    }
  }

  .jumbo-heading {
    font-size: 66px;
  }

  .page-heading {
    font-size: 50px;
  }

}
